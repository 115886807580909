/* eslint-disable prettier/prettier */
import { useState, useRef, useEffect } from "react";
import axiosInstance from "../../utils/axios/axios.instance";
import { DatePicker } from "@mui/x-date-pickers";
import { Grid } from "@mui/material";

function AddLogo() {
  const [file, setFile] = useState<File | null>(null);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [fundName, setFundName] = useState("");
  const [date, setDate] = useState<Date | null>(null);
  const [comment, setComment] = useState("");
  const addLogo = async () => {
    if (!file) {
      alert("Please select a file");
      return;
    } else {
      const formData = new FormData();
      formData.append("fundName", fundName);
      formData.append("date", date?.toString() || "");
      formData.append("file", file);
      formData.append("filename", file.name);
      const response = await axiosInstance.post("/uploadPdf", formData, {
        headers: {
          "Content-Type": "multipart/form-data" // or you can omit this line
        }
        });
      if (response.data.status === true) {
        setComment("file uploaded");
        setFile(null)
      } else {
        setComment("Error in uploading file");
      }
    }
  };
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };
  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (
      event.dataTransfer.items &&
      event.dataTransfer.items[0].kind === "file"
    ) {
      const droppedFile = event.dataTransfer.items[0].getAsFile();
      setFile(droppedFile);
    }
  };
  const [fundNames, setFundNames] = useState([]);
  const getFundNames = async () => {
    const response = await axiosInstance.get("/get_fund_names");
    setFundNames(response.data.data);
    setFundName(response.data.data[0]["fund_ID"]);
  };
  useEffect(() => {
    getFundNames();
  }, []);
  return (
    <div style={{ alignContent: "center", alignSelf: "centers" }}>
      Upload Fund PDF
      <div
        style={{
          marginLeft: "25%",
          width: "50%",
          alignContent: "center",
          alignItems: "center"
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <label htmlFor="fund-name-select">Fund Name</label>
            <select
              id="fund-name-select"
              onChange={(e) => setFundName(e.target.value)}
              style={{
                backgroundColor: "#f2f2f2",
                color: "#333",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                outline: "none",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                width: "200px",
                cursor: "pointer"
              }}
            >
              {fundNames.map((fund, index) => (
                <option key={index} value={fund["fund_ID"]}>
                  {fund["Fund_Name"]}
                </option>
              ))}
            </select>
          </Grid>

          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Reporting Date"
              value={date}
              onChange={(newValue) => {
                setDate(newValue);
              }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: "50px",
            border: "2px dashed gray",
            padding: "20px",
            cursor: "pointer",
            textAlign: "center"
          }}
          onDragOver={(event) => event.preventDefault()}
          onDrop={onDrop}
          onClick={() => inputFileRef.current?.click()} // 2. Use the ref to click the input
        >
          {file ? file.name : "Drag & Drop or Click to Select a File"}
          <input
            type="file"
            style={{ display: "none" }}
            onChange={onFileChange}
            onClick={(e: any) => (e.target.value = "")}
            ref={inputFileRef} // Attach the ref to the input
          />
        </div>
        <button
          onClick={addLogo}
          style={{
            marginLeft: "40%",
            marginTop: "20px",
            backgroundColor: "#673AB7",
            color: "white",
            border: "none",
            padding: "12px 24px",
            borderRadius: "4px",
            fontSize: "16px",
            fontWeight: "500",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s, box-shadow 0.2s",
            cursor: "pointer",
            outline: "none"
          }}
        >
          Process
        </button>

        {comment === "file uploaded" ? (
          <div
            style={{
              alignContent: "center",
              alignItems: "center",
              marginTop: "1rem",
              marginBottom: "10%",
              color: "Green"
            }}
          > {comment} </div>
        ) : (
          <div
            style={{
              alignContent: "center",
              alignItems: "center",
              marginTop: "1rem",
              marginBottom: "10%",
              color: "Red"
            }}
          > {comment} </div>
        )}
      </div>
    </div>
  );
}

export default AddLogo;
