import React, { useState, ChangeEvent, CSSProperties, useEffect } from "react";
import {
  IFundTrackRecord,
  ITeam,
  ITrackRecord,
} from "../../interfaces/fundRaising.interface";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TeamManagementDialog from "../../components/fundRaising/team";
import FundTrackRecordDialog from "../../components/fundRaising/fundTrack";
import TrackRecordDialog from "../../components/fundRaising/trackRecord";
import "./../../Style/dynamicForm2.css";
import TextField from "@mui/material/TextField";
import PreviousInvestDialog from "../../components/fundRaising/previousInvest";
interface DynamicFormProps {
  fieldData: {
    [fieldName: string]: {
      type: string | Boolean;
      defaultValue: string;
      name: string;
    };
  };
  changedata: (formData: {
    [fieldName: string]:
      | string
      | Boolean
      | ITeam[]
      | ITrackRecord[]
      | string[]
      | IFundTrackRecord[];
  }) => void;
}
interface FormData {
  [fieldName: string]:
    | string
    | Boolean
    | ITeam[]
    | ITrackRecord[]
    | string[]
    | IFundTrackRecord[];
}

const DynamicForm2: React.FC<DynamicFormProps> = ({
  fieldData,
  changedata,
}) => {
  useEffect(() => {
    const updatedFormState: FormData = {};
    setImageError(false);
    Object.keys(fieldData).forEach((fieldName) => {
      updatedFormState[fieldName] = fieldData[fieldName].defaultValue;
    });
    setFormData(updatedFormState);
    setTeam(updatedFormState["Team"] as ITeam[]);
    setFundTrackRecords(
      updatedFormState["Fund_Track_Record"] as IFundTrackRecord[]
    );
    setPreviousInvestImages(
      updatedFormState["previous_investments"] as string[]
    );

    settrackRecords(updatedFormState["Track_Record"] as ITrackRecord[]);
  }, [fieldData]);
  const initialFormState: FormData = {};
  Object.keys(fieldData).forEach((fieldName) => {
    initialFormState[fieldName] = fieldData[fieldName].defaultValue || "";
  });
  const handleFileChange = (event: any) => {
    console.log("event", event);
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      setFormData({
        ...formData,
        [event.target.name]: file, // Store the File object
      });
      changedFields[event.target.name] = file;
    }
  };
  const [teamDialogOpen, setTeamDialogOpen] = useState(false);
  const [team, setTeam] = useState<ITeam[]>([]);
  const [trackDialogOpen, setTrackDialogOpen] = useState(false);
  const [previousInvestDialogOpen, setPreviousInvestDialogOpen] =
    useState(false);
  const [fundTrackRecords, setFundTrackRecords] = useState<IFundTrackRecord[]>(
    []
  );
  const [trackRecords, settrackRecords] = useState<ITrackRecord[]>([]);
  const [previousInvestImages, setPreviousInvestImages] = useState<string[]>(
    []
  );
  const [fundDialogOpen, setFundDialogOpen] = useState(false);
  const handleOpenTeamDialog = () => {
    setTeamDialogOpen(true);
  };

  const handleCloseTeamDialog = () => {
    setTeamDialogOpen(false);
  };
  const handleOpenTrackDialog = () => {
    setTrackDialogOpen(true);
  };

  const handleCloseTrackDialog = () => {
    setTrackDialogOpen(false);
  };

  const handleOpenFundDialog = () => {
    setFundDialogOpen(true);
  };

  const handleCloseFundDialog = () => {
    setFundDialogOpen(false);
  };
  const handleOpenPreviousInvestDialog = () => {
    setPreviousInvestDialogOpen(true);
  };
  const handleClosePreviousInvestDialog = () => {
    setPreviousInvestDialogOpen(false);
  };
  const [imageError, setImageError] = useState(false);
  const handleAddTeamMember = () => {
    const newTeamMember: ITeam = {
      partner_name: "",
      partner_background: "",
      partner_url: "",
      profile_image: null,
    };
    setTeam([
      ...team,
      {
        partner_name: "",
        partner_background: "",
        partner_url: "",
        profile_image: null,
      },
    ]);
    setFormData({
      ...formData,
      Team: [...(formData["Team"] as ITeam[]), newTeamMember],
    });
  };

  const handleRemoveTeamMember = (index: number) => {
    const updatedTeam = [...(formData["Team"] as ITeam[])];
    updatedTeam.splice(index, 1);
    const newTeam = [...team];
    newTeam.splice(index, 1);
    setTeam(newTeam);
    setFormData({
      ...formData,
      Team: updatedTeam,
    });
    setChangedFields({
      ...changedFields,
      Team: updatedTeam,
    });
  };
  const handleAddTrackRecord = () => {
    const newTrackRecord: ITrackRecord = {
      tr_date: "",
      tr_fund: "",
      tr_vintage: "",
      tr_netTVPI: "",
      tr_lossRatio: "",
      tr_size: 0,
      tr_invested: 0,
      tr_realized: 0,
      tr_unrealized: 0,
      tr_grossIRR: "",
      tr_netIRR: "",
      tr_totalValue: "",
    };
    settrackRecords([...trackRecords, newTrackRecord]);
    setFormData({
      ...formData,
      Track_Record: [
        ...(formData["Track_Record"] as ITrackRecord[]),
        newTrackRecord,
      ],
    });
  };

  const handleAddFundRecord = () => {
    const newFundRecord: IFundTrackRecord = {
      ftr_company: "",
      ftr_currency: "",
      ftr_region: "",
      ftr_sector: "",
      ftr_stage: "",
      ftr_investmentDate: "", // Assuming date format is "DD-MM-YYYY"
      ftr_exitDate: "", // Assuming date format is "DD-MM-YYYY" or empty "" for no exit date
      ftr_invested: 0,
      ftr_realized: 0,
      ftr_unRealized: 0,
      ftr_grossIRR: "", // Assuming this can be an empty "" or a 0 as ""
      ftr_netIRR: "", // Assuming this can be an empty "" or a 0 as ""
    };
    setFundTrackRecords([
      ...fundTrackRecords,
      {
        ftr_company: "",
        ftr_currency: "",
        ftr_region: "",
        ftr_sector: "",
        ftr_stage: "",
        ftr_investmentDate: "",
        ftr_exitDate: "",
        ftr_invested: 0,
        ftr_realized: 0,
        ftr_unRealized: 0,
        ftr_grossIRR: "",
        ftr_netIRR: "",
      },
    ]);
    setFormData({
      ...formData,
      Fund_Track_Record: [
        ...(formData["Fund_Track_Record"] as IFundTrackRecord[]),
        newFundRecord,
      ],
    });
  };
  const handleRemoveTrackRecord = (index: number) => {
    const updatedTeam = [...(formData["Track_Record"] as ITrackRecord[])];
    updatedTeam.splice(index, 1);
    const updatedRecords = [...trackRecords];
    updatedRecords.splice(index, 1);
    settrackRecords(updatedRecords);
    setFormData({
      ...formData,
      Track_Record: updatedTeam,
    });
    setChangedFields({
      ...changedFields,
      Track_Record: updatedTeam,
    });
  };
  const handleRemoveFundRecord = (index: number) => {
    const updatedTeam = [
      ...(formData["Fund_Track_Record"] as IFundTrackRecord[]),
    ];
    updatedTeam.splice(index, 1);
    const updatedRecords = [...fundTrackRecords];
    updatedRecords.splice(index, 1);
    setFundTrackRecords(updatedRecords);
    setFormData({
      ...formData,
      Fund_Track_Record: updatedTeam,
    });
    setChangedFields({
      ...changedFields,
      Fund_Track_Record: updatedTeam,
    });
  };
  // Function to handle image load error
  const handleImageError = () => {
    setImageError(true);
  };

  const [formData, setFormData] = useState<FormData>(initialFormState);
  const [changedFields, setChangedFields] = useState<FormData>({});
  const styles: { [key: string]: CSSProperties } = {
    label: {
      fontWeight: "bold",
      padding: "10px",
      width: "20px", // Align the text to the right within the label cell
      verticalAlign: "top",
    },
    label2: {
      fontWeight: "bold",
      padding: "5px 20px",
    },
    input: {
      verticalAlign: "text-top",
      padding: "10px",
      border: "1px solid #ccc",
      width: "200px",
      borderRadius: "4px", // Make input take full width of its cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    button2: {
      marginLeft: "20px",
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };
  const handleTrackRecordChange = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const updatedTrack = [...(formData["Track_Record"] as ITrackRecord[])];
    const { name, value } = e.target;
    updatedTrack[index] = {
      ...updatedTrack[index],
      [name]: value,
    };
    const updatedRecords = [...trackRecords];
    updatedRecords[index] = {
      ...updatedRecords[index],
      [e.target.name]: e.target.value,
    };
    settrackRecords(updatedRecords);
    setFormData({
      ...formData,
      Track_Record: updatedTrack,
    });

    setChangedFields({
      ...changedFields,
      Track_Record: updatedTrack,
    });
  };
  const handleFundRecordChange = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const updatedTrack = [
      ...(formData["Fund_Track_Record"] as IFundTrackRecord[]),
    ];
    const { name, value } = e.target;
    updatedTrack[index] = {
      ...updatedTrack[index],
      [name]: value,
    };
    const updatedRecords = [...fundTrackRecords];
    updatedRecords[index] = {
      ...updatedRecords[index],
      [e.target.name]: e.target.value,
    };
    setFundTrackRecords(updatedRecords);
    setFormData({
      ...formData,
      Fund_Track_Record: updatedTrack,
    });

    setChangedFields({
      ...changedFields,
      Fund_Track_Record: updatedTrack,
    });
  };
  const handleTeamChange = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const updatedTeam = [...(formData["Team"] as ITeam[])];
    const { name, value } = e.target;
    updatedTeam[index] = {
      ...updatedTeam[index],
      [name]: value,
    };
    const newTeam = [...team];
    newTeam[index] = {
      ...newTeam[index],
      [e.target.name]: e.target.value,
    };
    setTeam(newTeam);
    setFormData({
      ...formData,
      Team: updatedTeam,
    });
    setChangedFields({
      ...changedFields,
      Team: updatedTeam,
    });
  };
  const handleTeamProfileChange = (index: number, event: any) => {
    const file = event.target.files[0]; // Get the selected file
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      const updatedTeam = [...(formData["Team"] as ITeam[])];
      updatedTeam[index] = {
        ...updatedTeam[index],
        profile_image: file,
      };
      setFormData({
        ...formData,
        Team: updatedTeam,
      });
      setChangedFields({
        ...changedFields,
        Team: updatedTeam,
      });
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const newTeam = [...team];
        newTeam[index] = {
          ...newTeam[index],
          profile_image: (e.target?.result as unknown as File) || null,
        };
        setTeam(newTeam);
      };
    } else {
      alert("Please select a valid image file (JPEG or PNG).");
      event.target.value = "";
    }
  };

  const handleAddImageUrl = () => {
    const newImageUrl = "";
    setPreviousInvestImages([...previousInvestImages, newImageUrl]);
    const arr = [...previousInvestImages, newImageUrl];
    setFormData({
      ...formData,
      previous_investments: [
        ...(formData["previous_investments"] as string[]),
        newImageUrl,
      ],
    });
    setChangedFields({
      ...changedFields,
      previous_investments: arr,
    });
  };
  const handleImageUploadChange = (index: number, event: any) => {
    const file = event.target.files[0]; // Get the selected file
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      const updatedImages = [...previousInvestImages];
      updatedImages[index] = file;

      setPreviousInvestImages(updatedImages);

      // Update the formData state, specifically the previous_investments array
      setFormData({
        ...formData,
        previous_investments: updatedImages,
      });
      setChangedFields({
        ...changedFields,
        previous_investments: updatedImages,
      });
    } else {
      alert("Please select a valid image file (JPEG or PNG).");
      event.target.value = "";
    }
  };

  const handleRemoveImageUrl = (index: number) => {
    const updatedImg = [...(formData["previous_investments"] as string[])];
    updatedImg.splice(index, 1);
    const updatedRecords = [...previousInvestImages];
    updatedRecords.splice(index, 1);
    setPreviousInvestImages(updatedRecords);
    setFormData({
      ...formData,
      previous_investments: updatedImg,
    });
    setChangedFields({
      ...changedFields,
      previous_investments: updatedImg,
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, type, checked, value } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
    setChangedFields({
      ...changedFields,
      [name]: newValue,
    });
  };
  const handleSubmitForm2 = () => {
    if (formData["portfolio_companyLogo"] != null) {
      const data = {
        portfolio_companyLogo: formData["portfolio_companyLogo"],
        ...changedFields,
      };
      changedata(data);
      setChangedFields({});
    } else if (formData["fund_logo"] != null) {
      const data = {
        fund_logo: formData["fund_logo"],
        ...changedFields,
      };
      changedata(data);
      setChangedFields({});
    } else {
      changedata(changedFields);
      setChangedFields({});
    }
  };
  const form = React.createRef<HTMLFormElement>();
  const filterKeyArray = [
    "pdf_link",
    "Team",
    "Track_Record",
    "Fund_Track_Record",
    "fr_deck_checkbox",
    "fr_deck_url",
    "fr_prospectus_checkbox",
    "fr_prospectus_url",
    "fr_esg_checkbox",
    "fr_esg_url",
    "fr_impactthesis_checkbox",
    "fr_impactthesis_curl",
    "previous_investments",
  ];
  const documentKeyArray = [
    "fr_deck_checkbox",
    "fr_deck_url",
    "fr_prospectus_checkbox",
    "fr_prospectus_url",
    "fr_esg_checkbox",
    "fr_esg_url",
    "fr_impactthesis_checkbox",
    "fr_impactthesis_curl",
  ];
  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div style={styles.buttonContainer}>
        <button
          type="button"
          style={styles.button2}
          onClick={handleSubmitForm2}
        >
          Update
        </button>
      </div>
      <form ref={form}>
        <table
          style={{
            width: "60%",
            borderCollapse: "collapse",
            borderSpacing: 0,
            marginTop: "20px",
          }}
        >
          <tbody>
            {Object.keys(fieldData)
              .filter((key) => !filterKeyArray.includes(key))
              .map((fieldName, index, array) => {
                const isGoalCheckbox = fieldName.includes("_checkbox");
                // Determine the name of the corresponding description field, if this is a checkbox
                const descriptionFieldName = isGoalCheckbox
                  ? fieldName.replace("_checkbox", "_description")
                  : null;
                const urlFieldName = isGoalCheckbox
                  ? fieldName.replace("_checkbox", "_url")
                  : "";
                const curlFieldName = isGoalCheckbox
                  ? fieldName.replace("_checkbox", "_curl")
                  : "";
                // Determine if the next field in the array is the corresponding description for this checkbox
                const isNextFieldDescription =
                  array[index + 1] === descriptionFieldName;
                const isNextFieldUrl = array[index + 1] === urlFieldName;
                const isNextFieldCUrl = array[index + 1] === curlFieldName;
                const desc =
                  fieldName.includes("_description") ||
                  fieldName.includes("_url") ||
                  fieldName.includes("_curl");

                return (
                  <tr
                    key={fieldName}
                    style={{
                      paddingTop: "30px",
                      marginBottom: "20px",
                    }}
                  >
                    {!desc && (
                      <td style={{ width: "30%", ...styles.label }}>
                        {fieldData[fieldName].name}
                      </td>
                    )}
                    {fieldData[fieldName].type === "file" ? (
                      <>
                        <td style={{ width: "70%" }}>
                          <input
                            type="file"
                            id={fieldName}
                            name={fieldName}
                            onChange={handleFileChange}
                            accept="image/jpeg, image/png"
                          />
                        </td>
                        <>
                          {fieldData[fieldName].defaultValue && !imageError ? (
                            <img
                              src={fieldData[fieldName].defaultValue as string}
                              width="100px"
                              height="100px"
                              onError={handleImageError}
                              alt="Dynamic Content"
                            />
                          ) : (
                            <p>No image available</p>
                          )}
                        </>
                      </>
                    ) : fieldData[fieldName].type === "checkbox" ? (
                      <>
                        <td
                          style={{
                            marginTop: "3px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="checkbox"
                            id={fieldName}
                            name={fieldName}
                            checked={Boolean(formData[fieldName])}
                            onChange={handleChange}
                            style={{}}
                          />
                          {(isNextFieldDescription ||
                            isNextFieldUrl ||
                            isNextFieldCUrl) &&
                            !!formData[fieldName] && (
                              <TextField
                                multiline
                                rows={
                                  String(
                                    formData[
                                      isNextFieldDescription
                                        ? descriptionFieldName
                                        : isNextFieldUrl
                                        ? urlFieldName
                                        : curlFieldName
                                    ]
                                  ).length /
                                    50 +
                                  1
                                }
                                label={fieldData[fieldName].name}
                                variant="outlined"
                                id={
                                  isNextFieldDescription
                                    ? descriptionFieldName
                                    : isNextFieldUrl
                                    ? urlFieldName
                                    : curlFieldName
                                }
                                name={
                                  isNextFieldDescription
                                    ? descriptionFieldName
                                    : isNextFieldUrl
                                    ? urlFieldName
                                    : curlFieldName
                                }
                                value={
                                  String(
                                    formData[
                                      isNextFieldDescription
                                        ? descriptionFieldName
                                        : isNextFieldUrl
                                        ? urlFieldName
                                        : curlFieldName
                                    ]
                                  ) || ""
                                }
                                onChange={handleChange}
                                style={{
                                  marginLeft: "10px",
                                  width: "84%",
                                  marginBottom: "10px",
                                  background: "#fff",
                                  borderColor: "#007bff",
                                  borderWidth: "1px",
                                  borderRadius: "4px", // Rounded corners
                                  padding: "5px", // Internal padding
                                }}
                              />
                            )}
                        </td>
                      </>
                    ) : (
                      !desc && ( // Only render non-checkbox fields or descriptions without a preceding checkbox
                        <td style={{ width: "100%" }}>
                          <TextField
                            multiline
                            rows={
                              Math.floor(String(formData[fieldName]).length) /
                                65 +
                              1
                            }
                            label={fieldData[fieldName].name}
                            variant="outlined"
                            style={{
                              textAlign: "justify",
                              width: "90%",
                              marginBottom: "10px",
                              background: "#fff", // Adds a white background
                              borderColor: "#007bff", // Blue border color
                              borderWidth: "2px", // Border width
                              borderRadius: "4px", // Rounded corners
                              padding: "5px", // Internal padding
                            }}
                            type={fieldData[fieldName].type as string}
                            id={index.toString()}
                            name={fieldName}
                            value={String(formData[fieldName]) || ""}
                            onChange={handleChange}
                            disabled={fieldName === "LP_name"}
                          />
                        </td>
                      )
                    )}
                  </tr>
                );
              })}
            {Object.keys(fieldData)
              .filter((key) => documentKeyArray.includes(key))
              .map((fieldName, index, array) => {
                const isGoalCheckbox = fieldName.includes("_checkbox");
                // Determine the name of the corresponding description field, if this is a checkbox
                const descriptionFieldName = isGoalCheckbox
                  ? fieldName.replace("_checkbox", "_description")
                  : null;
                const urlFieldName = isGoalCheckbox
                  ? fieldName.replace("_checkbox", "_url")
                  : "";
                const curlFieldName = isGoalCheckbox
                  ? fieldName.replace("_checkbox", "_curl")
                  : "";
                // Determine if the next field in the array is the corresponding description for this checkbox
                const isNextFieldDescription =
                  array[index + 1] === descriptionFieldName;
                const isNextFieldUrl = array[index + 1] === urlFieldName;
                const isNextFieldCUrl = array[index + 1] === curlFieldName;
                const desc =
                  fieldName.includes("_description") ||
                  fieldName.includes("_url") ||
                  fieldName.includes("_curl");
                return (
                  <tr
                    key={fieldName}
                    style={{
                      paddingTop: "30px",
                      marginBottom: "20px",
                    }}
                  >
                    {!desc && (
                      <td style={{ width: "30%", ...styles.label }}>
                        {fieldData[fieldName].name}
                      </td>
                    )}
                    {fieldData[fieldName].type === "checkbox" ? (
                      <>
                        <td
                          style={{
                            marginTop: "3px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="checkbox"
                            id={fieldName}
                            name={fieldName}
                            checked={Boolean(formData[fieldName])}
                            onChange={handleChange}
                            style={{}}
                          />
                          {(isNextFieldDescription ||
                            isNextFieldUrl ||
                            isNextFieldCUrl) &&
                            !!formData[fieldName] && (
                              <>
                                <input
                                  style={{
                                    marginLeft: "10px",
                                  }}
                                  type="file"
                                  id={fieldName}
                                  name={
                                    isNextFieldDescription
                                      ? descriptionFieldName
                                      : isNextFieldUrl
                                      ? urlFieldName
                                      : curlFieldName
                                  }
                                  onChange={handleFileChange}
                                  accept="application/pdf"
                                />
                                {String(
                                  formData[
                                    isNextFieldDescription
                                      ? descriptionFieldName
                                      : isNextFieldUrl
                                      ? urlFieldName
                                      : curlFieldName
                                  ]
                                ) !== "" ? (
                                  <a
                                    href={String(
                                      formData[
                                        isNextFieldDescription
                                          ? descriptionFieldName
                                          : isNextFieldUrl
                                          ? urlFieldName
                                          : curlFieldName
                                      ]
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View previous PDF
                                  </a>
                                ) : (
                                  <p>No PDF available</p>
                                )}
                              </>
                            )}
                        </td>
                      </>
                    ) : (
                      <> </>
                    )}
                  </tr>
                );
              })}
            <tr>
              <td style={{ height: "10px" }} colSpan={2}></td>
            </tr>
            <tr style={{ paddingTop: "50px" }}>
              <td style={{ width: "30%", ...styles.label }}>Team management</td>
              <td
                style={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleOpenTeamDialog}
                >
                  Update Team
                </Button>
              </td>
            </tr>
            <tr>
              <td style={{ height: "10px" }} colSpan={2}></td>
            </tr>
            <tr style={{ paddingTop: "50px" }}>
              <td style={{ width: "30%", ...styles.label }}>
                Track Record management
              </td>
              <td
                style={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleOpenTrackDialog}
                >
                  Update Track Record
                </Button>
              </td>
            </tr>
            <tr>
              <td style={{ height: "10px" }} colSpan={2}></td>
            </tr>
            <tr style={{ paddingTop: "50px" }}>
              <td style={{ width: "30%", ...styles.label }}>
                Fund Track management
              </td>
              <td
                style={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleOpenFundDialog}
                >
                  Update Fund Track
                </Button>
              </td>
            </tr>
            <tr>
              <td style={{ height: "10px" }} colSpan={2}></td>
            </tr>
            <tr style={{ paddingTop: "50px" }}>
              <td style={{ width: "30%", ...styles.label }}>
                Previous Investments
              </td>
              <td
                style={{
                  width: "70%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleOpenPreviousInvestDialog}
                >
                  Previous Investments
                </Button>
              </td>
            </tr>
            <TeamManagementDialog
              open={teamDialogOpen}
              onClose={handleCloseTeamDialog}
              team={team}
              onTeamChange={handleTeamChange}
              onAddTeamMember={handleAddTeamMember}
              onRemoveTeamMember={handleRemoveTeamMember}
              handleTeamProfileChange={handleTeamProfileChange}
            />
            <FundTrackRecordDialog
              open={fundDialogOpen}
              onClose={handleCloseFundDialog}
              fundTrackRecords={fundTrackRecords}
              onFundTrackRecordChange={handleFundRecordChange}
              onAddFundTrackRecord={handleAddFundRecord}
              onRemoveFundTrackRecord={handleRemoveFundRecord}
            />
            <TrackRecordDialog
              open={trackDialogOpen}
              onClose={handleCloseTrackDialog}
              trackRecords={trackRecords}
              onTrackRecordChange={handleTrackRecordChange}
              onAddTrackRecord={handleAddTrackRecord}
              onRemoveTrackRecord={handleRemoveTrackRecord}
            />
            <PreviousInvestDialog
              open={previousInvestDialogOpen}
              onClose={handleClosePreviousInvestDialog}
              previousInvestment={previousInvestImages}
              onPreviousInvestChange={handleImageUploadChange}
              onAddPreviousInvest={handleAddImageUrl}
              onRemovePreviousInvest={handleRemoveImageUrl}
            />
          </tbody>
        </table>
      </form>
    </div>
  );
};

export default DynamicForm2;
