import { CSSProperties, useState } from "react";
import axiosInstance from "../../utils/axios/axios.instance";
import DynamicForm from "./dynamicForm";
import {
  FundStaticProps,
  IFundStatic,
} from "../../interfaces/fundStatic.interface";

function FundStatic({ id }: FundStaticProps) {
  const [fieldData, setFieldData] = useState({});
  const [message, setMessage] = useState("");
  const styles: { [key: string]: CSSProperties } = {
    label: {
      fontWeight: "bold",
      padding: "10px", // Add padding for aesthetic spacing
      textAlign: "right", // Align the text to the right within the label cell
    },
    input: {
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "100%", // Make input take full width of its cell
    },
    defaultValue: {
      padding: "10px", // Add padding for aesthetic spacing
      marginLeft: "20px", // Space from the input cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
      marginBottom: "20px",
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };
  function createFieldData(responseData: IFundStatic): {
    [key: string]: {
      type: string | Date;
      defaultValue: string | undefined | Date | File;
    };
  } {
    const fieldData: {
      [key: string]: {
        type: string | Date;
        defaultValue: string | undefined | Date | File;
      };
    } = {};

    Object.keys(responseData).forEach((key) => {
      if (
        key === "_id" ||
        key === "ReportingDateKey" ||
        key === "ReportingDateString" ||
        key === "KeyFundDate" ||
        key === "keyLpFund"
      ) {
        // Exclude specific fields from being added to fieldData
      } else if (key === "fund_logo") {
        fieldData[key] = {
          type: "file",
          defaultValue: responseData[key as keyof IFundStatic] as
            | File
            | undefined,
        };
      } else {
        fieldData[key] = {
          type: "text",
          defaultValue: responseData[key as keyof IFundStatic] as
            | string
            | undefined,
        };
      }
    });
    return fieldData;
  }

  // Define an async function inside useEffect
  const fetchData = async () => {
    if (id == null || id === "") {
      setMessage("Please select a fund ID");
      return;
    } else {
      try {
        // Use params to send query parameters with a GET request
        const response = await axiosInstance.get("/getStaticFund/" + id);
        if (response.data === "error") {
          setMessage("Error while fetching Data.");
          // Handle error
        } else {
          if (response.data.data.length === 0) {
            setFieldData({});
            setMessage("There is no data with the given Date");
          } else {
            const responseData = response.data.data[0]; // Assuming this is your response data
            setFieldData(createFieldData(responseData));
            setMessage("");
          }
        }
      } catch (err) {
        setMessage("Error while fetching Data.");
        console.error(err);
      }
    }
  };
  const handleChangeData = async (
    formData: {
      [fieldName: string]: string;
    },
    updateType: String
  ) => {
    if (updateType === "add") {
      return;
    } else if (updateType === "update") {
      try {
        const {
          fund_ID: idToRemove,
          Reporting_Date: dateToRemove,
          ...updatedFormData
        } = formData;
        const form = new FormData();
        form.append("fund_ID", id);
        form.append("file", updatedFormData.fund_logo);
        delete updatedFormData.fund_logo;
        form.append("formData", JSON.stringify(updatedFormData));
        const response = await axiosInstance.post("/update_fundStatic", form, {
          headers: {
            "Content-Type": "multipart/form-data", // or you can omit this line
          },
        });
        if (response.data === "error") {
          setMessage("Error while updating Data.");
        } else {
          setFieldData({});
          setMessage("Data updated successfully");
        }

        // Assuming you have the Base64 encoded ZIP string in a variable called `zipString`
      } catch (err) {
        setMessage("Error while updating Data.");
        console.log(err);
      }
    }
  };
  return (
    <div>
      <h1>Fund Static</h1>
      <div style={styles.buttonContainer}>
        <button type="submit" style={styles.button} onClick={fetchData}>
          get previous data
        </button>
      </div>
      {Object.keys(fieldData).length !== 0 && (
        <>
          <DynamicForm
            fieldData={fieldData}
            changedata={handleChangeData}
            staticData={true}
          />
        </>
      )}
      {message !== "" && <>{message}</>}
    </div>
  );
}
export default FundStatic;
