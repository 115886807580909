// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

import Page404 from "./pages/auth/Page404";

// Landing
import Landing from "./pages/presentation/Landing";

import DDQ from "./pages/pages/Orders";
import LongSummary from "./pages/pages/long_summary";
import NewsPage from "./pages/pages/news";
import Schema from "./pages/pages/schema";
import AddData from "./pages/pages/addData";
import AddLogo from "./pages/pages/add_logo";
import ProtectedRoute from "./components/Auth0/ProtectedRoutes";
import Dashboard from "./layouts/Dashboard";
import UpdateScriptData from "./pages/pages/Update_script_data";
import AddExtraction from "./pages/pages/addExtraction";
import UpdateFunds from "./pages/pages/update_fundRaising";
import Update_AIData from "./pages/pages/Update_AIData";

const routes = [
  {
    path: "/",
    element: <Dashboard />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={Landing} />,
      },
    ],
  },
  {
    path: "pdf_extract",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={DDQ} />,
      },
    ],
  },
  {
    path: "file_extract",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={AddExtraction} />,
      },
    ],
  },
  {
    path: "pdf_summary",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={LongSummary} />,
      },
    ],
  },
  {
    path: "portfolio_summary",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={NewsPage} />,
      },
    ],
  },
  {
    path: "schema_generator",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={Schema} />,
      },
    ],
  },
  {
    path: "add_data",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={AddData} />,
      },
    ],
  },
  {
    path: "Update_script_data",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={UpdateScriptData} />,
      },
    ],
  },
  {
    path: "add_pdf",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={AddLogo} />,
      },
    ],
  },
  {
    path: "update_fundRaising",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={UpdateFunds} />,
      },
    ],
  },
  {
    path: "Update_AIData",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ProtectedRoute component={Update_AIData} />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <ProtectedRoute component={Page404} />,
      },
    ],
  },
];

export default routes;
