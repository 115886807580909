import { CSSProperties, useState } from "react";
import axiosInstance from "../../utils/axios/axios.instance";
import DynamicForm from "./dynamicForm";
import {
  IPortfolioQtr,
  PortfolioQtrProps,
} from "../../interfaces/portfolioQtr.interface";

function PortfolioQtr({ data, id }: PortfolioQtrProps) {
  const priorityKeys = [
    "portfolio_companyName",
    "portfolio_firstInvestmentDate",
    "portfolio_exitDate",
    "unrealizedInvtCost",
    "realizedInvtGainLoss",
    "Reporting_Date",
  ];
  const [fieldData, setFieldData] = useState({});
  const [message, setMessage] = useState("");
  const styles: { [key: string]: CSSProperties } = {
    label: {
      fontWeight: "bold",
      padding: "10px", // Add padding for aesthetic spacing
      textAlign: "right", // Align the text to the right within the label cell
    },
    input: {
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "100%", // Make input take full width of its cell
    },
    defaultValue: {
      padding: "10px", // Add padding for aesthetic spacing
      marginLeft: "20px", // Space from the input cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
      marginBottom: "20px",
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };
  function createFieldData(responseData: IPortfolioQtr): {
    [key: string]: {
      type: string | Date;
      defaultValue: string | undefined | Date;
    };
  } {
    const fieldData: {
      [key: string]: {
        type: string | Date;
        defaultValue: string | undefined | Date;
      };
    } = {};
    Object.keys(responseData).forEach((key) => {
      if (
        key === "_id" ||
        key === "ReportingDateKey" ||
        key === "ReportingDateString" ||
        key === "KeyFundDate" ||
        key === "KeyFundInvDate" ||
        key === "keyLpFund"
      ) {
        // Exclude specific fields from being added to fieldData
      } else {
        if (key === "Reporting_Date") {
          // Check if the field name is the date field you want to set as "type: date"
          fieldData[key] = {
            type: "date",
            defaultValue: responseData[key as keyof IPortfolioQtr] as Date,
          };
        } else {
          fieldData[key] = {
            type: "text",
            defaultValue: responseData[key as keyof IPortfolioQtr] as
              | string
              | undefined,
          };
        }
      }
    });
    return fieldData;
  }

  function createOrderedFieldData(responseData: any, orderedKeys: string[]) {
    const prioritizedData: any = {};

    priorityKeys.forEach((key) => {
      if (responseData.hasOwnProperty(key)) {
        prioritizedData[key] = responseData[key];
      }
    });

    // Now add the rest of the keys
    Object.keys(responseData).forEach((key) => {
      if (!priorityKeys.includes(key)) {
        prioritizedData[key] = responseData[key];
      }
    });
    return prioritizedData;
  }
  // Define an async function inside useEffect
  const fetchData = async () => {
    setMessage("");
    if (data == null || data === "" || id == null || id === "") {
      setMessage("Please select a fund ID and a date");
      return;
    } else {
      try {
        const response = await axiosInstance.get(
          "/getPortfolioQtr/" + data + "/" + id
        );
        if (response.data === "error") {
          setMessage("Error while fetching Data.");
        } else {
          if (response.data.data.length === 0) {
            setMessage("There is no data with the given Date");
            setFieldData({});
          } else {
            const responseData = response.data.data[0]; // Assuming this is your response data
            // setFieldData(createFieldData(responseData));
            setFieldData(
              createOrderedFieldData(
                createFieldData(responseData),
                priorityKeys
              )
            );
            setMessage("");
          }
          // Handle success
        }
      } catch (err) {
        setMessage("Error while fetching Data.");
        console.error(err);
      }
    }
  };
  async function handleChangeData(
    formData: Record<string, string>,
    updateType: string
  ) {
    if (updateType !== "add" && updateType !== "update") {
      setMessage("Invalid update type");
      return;
    }
    try {
      let response;
      if (updateType === "add") {
        response = await axiosInstance.post("/add_portfolioQtr", { formData });
      } else {
        const updatedFormData = { ...formData };
        delete updatedFormData.company_ID;
        delete updatedFormData.Reporting_Date;
        delete updatedFormData.portfolio_companyName;
        delete updatedFormData.fund_ID;
        delete updatedFormData.Fund_Name;
        response = await axiosInstance.patch("/update_portfolioQtr", {
          company_ID: id,
          Reporting_Date: data,
          formData: updatedFormData,
        });
      }

      if (response.data === "error") {
        setMessage("Error occurred while processing data");
      } else {
        setMessage(
          `Data successfully ${updateType === "add" ? "added" : "updated"}`
        );
        setFieldData({});
      }
    } catch (err) {
      console.error(err);
      setMessage("An error occurred while processing data");
    }
  }
  return (
    <div>
      <h1>Portfolio Qtr</h1>
      <div style={styles.buttonContainer}>
        <button type="submit" style={styles.button} onClick={fetchData}>
          get previous data
        </button>
      </div>
      {message !== "" && <>{message}</>}
      {Object.keys(fieldData).length !== 0 && (
        <>
          <DynamicForm
            fieldData={fieldData}
            changedata={handleChangeData}
            staticData={false}
          />
        </>
      )}
    </div>
  );
}
export default PortfolioQtr;
