/* eslint-disable prettier/prettier */
import { useState, useRef, useEffect } from "react";
import axiosInstance from "../../utils/axios/axios.instance";
import CustomScrollComponent from "./scroll";
// YourComponent.tsx
import { Fund } from "./schema_types"; // Adjust the import path as necessary
import React from "react";
import { Button } from "@mui/material";

// ... Rest of your component code

function Schema() {
  const [oldFundData, setOldFundData] = useState({});
  const [newFundData, setNewFundData] = useState({});
  const [oldKeys, setOldKeys] = useState<string[]>([]);
  const [newKeys, setNewKeys] = useState<string[]>([]);
  const [oldValue, setOldValue] = useState<string>("");
  const [newValue, setNewValue] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [data, setData] = useState("");
  const [funds, setFunds] = useState<Fund[]>([]); // Initialize state with the correct type
  const inputFundID = useRef<HTMLSelectElement>(null);
  const inputDatePicker = useRef<HTMLInputElement | null>(null);
  const inputOldKey = useRef<HTMLSelectElement>(null);
  const inputNewKey = useRef<HTMLSelectElement>(null);
  const inputNewValue = useRef<HTMLInputElement | null>(null);
  const inputOldValue = useRef<HTMLInputElement | null>(null);
  const inputPDFRef = useRef<HTMLInputElement | null>(null);
  const getLastQuarterDate = (inputDateStr: string): string => {
    // Parse the input date string
    const [day, month, year] = inputDateStr.split("-").map(Number);
    const inputDate = new Date(year, month - 1, day); // JS months are 0-indexed

    // Determine the current quarter
    const currentQuarter = Math.floor((inputDate.getMonth() + 3) / 3);

    // Calculate the year and quarter of the last quarter
    let lastQuarterYear =
      currentQuarter === 1
        ? inputDate.getFullYear() - 1
        : inputDate.getFullYear();
    let lastQuarter = currentQuarter === 1 ? 4 : currentQuarter - 1;
    // Calculate the end date of the last quarter
    let lastQuarterEndDate = new Date(lastQuarterYear, lastQuarter * 3, 0);

    // Format the date into dd-mm-yyyy
    const formatDate = (date: Date): string => {
      const d = date.getDate();
      const m = date.getMonth() + 1; // JS months are 0-indexed
      const y = date.getFullYear();
      return `${d < 10 ? "0" + d : d}-${m < 10 ? "0" + m : m}-${y}`;
    };
    return formatDate(lastQuarterEndDate);
  };

  useEffect(() => {
    getID();
  }, []);
  const getID = async () => {
    try {
      const response = await axiosInstance.get<Fund[], any>("/get_fund_names"); // Type the response with <Fund[]>
      if (response.data.data !== "error") {
        setFunds(response.data.data); // No error because response.data is typed as Fund[]
      } else {
        // Handle the error case as appropriate
        console.error("Not enough data found");
      }
    } catch (error) {
      // Handle the error case as appropriate
      console.error("There was an error fetching the fund names", error);
    }
  };

  const changeOldValue = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const name: string = inputOldKey.current ? inputOldKey.current.value : "";
    const value = (oldFundData as Record<string, any>)[name];

    if (typeof value !== "undefined") {
      setOldValue(value);
    } else {
      console.log("Key not found in oldFundData");
    }
  };
  const changeNewValue = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const name: string = inputNewKey.current ? inputNewKey.current.value : "";
    const value = (newFundData as unknown as Record<string, any>)[name];
    if (typeof value !== "undefined") {
      setNewValue(value);
    } else {
      console.log("Key not found in oldFundData");
    }
  };
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };
  const formatDate = (dateString: string) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };
  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (
      event.dataTransfer.items &&
      event.dataTransfer.items[0].kind === "file"
    ) {
      const droppedFile = event.dataTransfer.items[0].getAsFile();
      setFile(droppedFile);
    }
  };
  useEffect(() => {
    if (inputOldValue.current !== null) {
      const data: Record<string, any> = oldFundData;
      setOldValue(data.Reporting_Date);
    }

    // if (inputNewValue.current !== null && inputNewKey.current !== null) {
    //   // Get the key from inputNewKey's current value
    //   const key = inputNewKey.current.value;

    //   // Ensure the key is a property of newFundData
    //   if (Object.keys(newFundData).length !== 0 && key in newFundData) {
    //     const data: Record<string, any> = newFundData;
    //     // Get the value corresponding to the key from newFundData
    //     const value = data[key];
    //     // Set the retrieved value to the newValue state
    //     setNewValue(value);
    //   } else {
    //     // Handle the case where the key does not exist in newFundData
    //     console.error(`The key '${key}' is not found in newFundData.`);
    //   }
    // }
  }, [oldFundData]);
  const getCompanyData = async () => {
    try {
      const fundID = inputFundID.current ? inputFundID.current.value : "";
      const date = formatDate(selectedDate);
      const lastDate = getLastQuarterDate(date);
      const response = await axiosInstance.get(
        `/get_fund_date/${lastDate}/${fundID}`,
        {
          params: {
            date: selectedDate,
            fund_ID: inputFundID.current ? inputFundID.current.value : "",
          },
        }
      );
      setOldFundData(response.data.data[0]);
      setOldKeys(Object.keys(response.data.data[0]));
    } catch (error) {
      // Handle the error case as appropriate
      console.error("There was an error fetching the company data", error);
    }
  };

  const handleInputChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setNewValue(event.target.value);
  };
  const getData = async (time: String, name: string) => {
    let retryCount = 0; // or whatever limit you'd like
    const interval = setInterval(async () => {
      try {
        const response = await axiosInstance.get("/getSchemaData", {
          params: {
            timestamp: time,
            name: name,
          },
        });

        if (response.data.data !== "Not yet ready") {
          setData("");
          setNewFundData(response.data);
          let data = response.data;
          if (inputNewValue.current !== null) {
            inputNewValue.current.value = data.Reporting_date;
          }
          setNewKeys(Object.keys(response.data));
          clearInterval(interval);
        } else if (response.data.data === "Not yet ready") {
          setData("the process taking loner than usual time!");
          retryCount++;
        } else if (retryCount >= 20) {
          clearInterval(interval);
          setData(
            "the process taking loner than usual time! you can try again later!..... "
          );
        } else {
          setData("Please wait, the file is being prepared...");
          retryCount++;
        }
      } catch (err) {
        console.log(err);
        // Maybe increase the retry count or handle the error in another way
        retryCount++;
      }
    }, 30000);
  };
  const updateAll = async () => {
    const name: string = inputNewKey.current ? inputNewKey.current.value : "";
    const newVal: string = inputNewValue.current
      ? inputNewValue.current.value
      : "";
    // Update the value associated with the key 'name' in newFundData
    setNewFundData((prevData) => {
      // Create a copy of the previous data to avoid mutating it directly
      const newData: Record<string, any> = { ...prevData };
      newData[name] = newVal; // Update the value

      return newData;
    });
  };

  const runscript = async () => {
    setNewValue("");
    setOldValue("");
    setNewFundData({});
    setOldFundData([]);
    setOldKeys([]);
    setNewKeys([]);

    getCompanyData();
    if (file) {
      let time = new Date().toISOString();
      time = time.replace(/:/g, "-");
      setData("Preparing output... ");
      setTimeout(() => {
        getData(time, file.name.slice(0, -4));
      }, 30000);
      try {
        const formData = new FormData();
        formData.append("pdf", file); // 'pdf' is the name the backend will use to retrieve this file

        await axiosInstance.post("/runSchema", formData, {
          headers: { "Content-Type": "multipart/form-data" },
          params: { timestamp: time },
        });
        // Assuming you have the Base64 encoded ZIP string in a variable called `zipString`
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("No file selected.");
    }
  };
  const [selectedDate, setSelectedDate] = useState("");

  const handleDateChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedDate(event.target.value);
  };
  return (
    <div style={{ alignContent: "center", alignSelf: "center" }}>
      <h2>Fund Overview</h2>
      <div
        style={{
          marginTop: "50px",
          border: "2px dashed gray",
          padding: "20px",
          cursor: "pointer",
          textAlign: "center",
        }}
        onDragOver={(event) => event.preventDefault()}
        onDrop={onDrop}
        onClick={() => inputPDFRef.current?.click()} // 2. Use the ref to click the input
      >
        {file ? file.name : "Drag & Drop or Click to Select a File"}
        <input
          type="file"
          style={{ display: "none" }}
          onChange={onFileChange}
          onClick={(e: any) => (e.target.value = "")}
          ref={inputPDFRef} // Attach the ref to the input
        />
      </div>
      <div
        style={{
          marginLeft: "15%",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <select
          ref={inputFundID}
          style={{
            backgroundColor: "#f2f2f2",
            color: "#333",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            outline: "none",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
            width: "200px",
            cursor: "pointer",
          }}
        >
          {Array.isArray(funds) &&
            funds.map((fund: any) => (
              <option
                key={fund.fund_ID} // Assuming 'fund_ID' is the unique identifier
                value={fund.fund_ID}
                style={{
                  backgroundColor: "white",
                  color: "#333",
                }}
              >
                {fund.Fund_Name}
                display
              </option>
            ))}
        </select>
        <input
          type="date"
          value={selectedDate}
          ref={inputDatePicker}
          onChange={handleDateChange}
          style={{
            padding: "10px",
            marginLeft: "2rem",
            border: "1px solid #ccc",
            borderRadius: "4px",
            fontSize: "16px",
            color: "#333",
            boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
            appearance: "none", // Removes default arrow on Internet Explorer
            backgroundColor: "white",
            cursor: "pointer",
            outline: "none",
            margin: "5px",
          }}
        />
        <button
          onClick={runscript}
          style={{
            marginLeft: "6rem",
            marginTop: "20px",
            backgroundColor: "#673AB7",
            color: "white",
            border: "none",
            padding: "12px 24px",
            borderRadius: "4px",
            fontSize: "16px",
            fontWeight: "500",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s, box-shadow 0.2s",
            cursor: "pointer",
            outline: "none",
          }}
        >
          Process
        </button>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ marginTop: "3rem", width: "50%" }}>
          {Object.keys(oldFundData).length !== 0 && (
            <>
              <h1>Previous Quarter Fund Details</h1>
              <CustomScrollComponent width="95%" height="200px">
                <div>
                  {oldFundData &&
                    Object.entries(oldFundData).map(([key, value]) => {
                      // Skip the _id field
                      if (key === "_id") return null;
                      // For other fields, check if the value is an empty string and replace it with "None"
                      let displayValue = "";
                      if (value === "") {
                        displayValue = "None";
                      } else if (typeof value === "object") {
                        // If value is an object, convert it to a string
                        displayValue = JSON.stringify(value);
                      } else if (value === undefined) {
                        // Handle undefined values
                        displayValue = "Not available";
                      } else {
                        displayValue = String(value);
                      }
                      return (
                        <div key={key} style={{ paddingTop: "1px" }}>
                          <strong>{key}:</strong> {displayValue}
                        </div>
                      );
                    })}
                </div>
              </CustomScrollComponent>
            </>
          )}
        </div>
        <div style={{ marginTop: "3rem", width: "50%" }}>
          {Object.keys(oldFundData).length !== 0 && (
            <>
              <h1>Current Quarter Generated Details</h1>
              <CustomScrollComponent width="95%" height="200px">
                <div>
                  {data !== "" && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div style={{ marginTop: "3rem" }}> {data} </div>
                      </div>
                    </>
                  )}
                  {newFundData &&
                    Object.entries(newFundData).map(([key, value]) => {
                      // Skip the _id field
                      if (key === "_id") return null;
                      let displayValue = "";
                      if (value === "") {
                        displayValue = "None";
                      } else if (typeof value === "object") {
                        // If value is an object, convert it to a string
                        displayValue = JSON.stringify(value);
                      } else if (value === undefined) {
                        // Handle undefined values
                        displayValue = "Not available";
                      } else {
                        // For other non-object types (like number, boolean), convert to string
                        displayValue = String(value);
                      }
                      return (
                        <div key={key} style={{ paddingTop: "1px" }}>
                          <strong>{key}:</strong> {displayValue}
                        </div>
                      );
                    })}
                </div>
              </CustomScrollComponent>
            </>
          )}
        </div>
      </div>
      {Object.keys(newFundData).length !== 0 && (
        <div
          style={{
            backgroundColor: "white",
            marginTop: "4rem",
            padding: "2rem",
            display: "flex",
            flexDirection: "row", // Added to organize in a column
            alignItems: "flex-start", // Align items to the start of the flex container
          }}
        >
          <div style={{ marginBottom: "1rem", width: "20%" }}>
            <div style={{ marginBottom: "0.5rem", paddingLeft: "1rem" }}>
              Old Key
            </div>
            <select
              onChange={changeOldValue}
              ref={inputOldKey}
              style={{
                backgroundColor: "#f2f2f2",
                color: "#333",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                outline: "none",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                width: "200px",
                cursor: "pointer",
              }}
            >
              {Array.isArray(oldKeys) &&
                oldKeys.map(
                  (key) =>
                    key !== "_id" && (
                      <option
                        key={key}
                        value={key}
                        style={{
                          backgroundColor: "white",
                          color: "#333",
                        }}
                      >
                        {key}
                      </option>
                    )
                )}
            </select>
          </div>
          <div
            style={{ marginBottom: "1rem", marginLeft: "2rem", width: "20%" }}
          >
            <div style={{ marginBottom: "0.5rem", paddingLeft: "1rem" }}>
              New Key
            </div>
            <select
              onChange={changeNewValue}
              ref={inputNewKey}
              style={{
                backgroundColor: "#f2f2f2",
                color: "#333",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                outline: "none",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                width: "200px",
                cursor: "pointer",
              }}
            >
              {Array.isArray(oldKeys) &&
                newKeys.map(
                  (key) =>
                    key !== "_id" && (
                      <option
                        key={key}
                        value={key}
                        style={{
                          backgroundColor: "white",
                          color: "#333",
                        }}
                      >
                        {key}
                      </option>
                    )
                )}
            </select>
          </div>

          <div
            style={{ marginBottom: "1rem", marginLeft: "2rem", width: "20%" }}
          >
            <div style={{ marginBottom: "0.5rem", paddingLeft: "1rem" }}>
              New Value
            </div>
            <input
              type="text"
              value={newValue}
              onChange={handleInputChange}
              ref={inputNewValue}
              style={{
                width: "200px",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                color: "#333",
                backgroundColor: "#f9f9f9",
              }}
            />
          </div>
          <div
            style={{ marginBottom: "1rem", marginLeft: "2rem", width: "20%" }}
          >
            <div style={{ marginBottom: "0.5rem", paddingLeft: "1rem" }}>
              Old Value
            </div>
            <input
              type="text"
              value={oldValue}
              ref={inputOldValue}
              readOnly
              style={{
                display: "block",
                width: "200px",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                color: "#333",
                backgroundColor: "#f9f9f9",
                cursor: "not-allowed",
              }}
            />
          </div>
          <div
            style={{ width: "50%", marginTop: "1.7rem", marginLeft: "2rem" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={updateAll}
              style={{
                backgroundColor: "blue", // Background color
                color: "white", // Text color
                borderRadius: "8px", // Border radius
                padding: "7px 15px", // Padding
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Box shadow
                fontSize: "12px", // Font size
                fontWeight: "bold", // Font weight
                cursor: "pointer", // Cursor style
              }}
            >
              Update
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Schema;
