import React, {
  useState,
  ChangeEvent,
  FormEvent,
  CSSProperties,
  useEffect,
} from "react";

interface DynamicFormProps {
  fieldData: {
    [fieldName: string]: {
      type: string;
      defaultValue: string;
    };
  };
  changedata: (
    formData: { [fieldName: string]: string },
    updateType: "add" | "update"
  ) => void;
  staticData: boolean;
}

interface FormData {
  [fieldName: string]: string;
}

const DynamicForm: React.FC<DynamicFormProps> = ({
  fieldData,
  changedata,
  staticData,
}) => {
  useEffect(() => {
    if (staticData) {
      const updatedFormState: FormData = {};
      setImageError(false);
      Object.keys(fieldData).forEach((fieldName) => {
        updatedFormState[fieldName] = fieldData[fieldName].defaultValue;
      });
      setFormData(updatedFormState);
    } else {
      const updatedFormState: FormData = {};
      Object.keys(fieldData).forEach((fieldName) => {
        updatedFormState[fieldName] = "";
      });
      setFormData(updatedFormState);
    }
  }, [fieldData, staticData]);
  const initialFormState: FormData = {};
  const [update, setUpdate] = useState(false);
  if (staticData) {
    Object.keys(fieldData).forEach((fieldName) => {
      initialFormState[fieldName] = fieldData[fieldName].defaultValue || "";
    });
  } else {
    Object.keys(fieldData).forEach((fieldName) => {
      initialFormState[fieldName] = "";
    });
  }
  const handleFileChange = (event: any) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      setFormData({
        ...formData,
        [event.target.name]: file, // Store the File object
      });
    }
  };
  const [imageError, setImageError] = useState(false);

  // Function to handle image load error
  const handleImageError = () => {
    setImageError(true);
  };

  const [formData, setFormData] = useState<FormData>(initialFormState);
  const [changedFields, setChangedFields] = useState<FormData>({});
  const styles: { [key: string]: CSSProperties } = {
    label: {
      fontWeight: "bold",
      padding: "10px",
      width: "20px", // Align the text to the right within the label cell
      verticalAlign: "top",
    },
    label2: {
      fontWeight: "bold",
      padding: "5px 20px",
    },
    input: {
      verticalAlign: "text-top",
      padding: "10px",
      border: "1px solid #ccc",
      width: "200px",
      borderRadius: "4px", // Make input take full width of its cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
    button2: {
      marginLeft: "20px",
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setChangedFields({
      ...changedFields,
      [name]: value,
    });
  };
  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUpdate(false);
    // Call the onSubmit prop with the formData
    changedata(formData, "add");
    setChangedFields({});
  };
  const handleSubmitForm2 = () => {
    if (formData["portfolio_companyLogo"] != null) {
      const data = {
        portfolio_companyLogo: formData["portfolio_companyLogo"],
        ...changedFields,
      };
      changedata(data, "update");
      setUpdate(true);
      setChangedFields({});
    } else if (formData["fund_logo"] != null) {
      const data = {
        fund_logo: formData["fund_logo"],
        ...changedFields,
      };
      changedata(data, "update");
      setUpdate(true);
      setChangedFields({});
    } else {
      changedata(changedFields, "update");
      setUpdate(true);
      setChangedFields({});
    }
  };
  const form = React.createRef<HTMLFormElement>();
  if (!staticData) {
    return (
      <div>
        <form onSubmit={handleSubmitForm} ref={form}>
          <table>
            <tbody>
              {Object.keys(fieldData)
                .filter((key) => key !== "pdf_link")
                .map((fieldName, index, array) => (
                  <tr
                    key={fieldName}
                    style={{
                      paddingTop: "30px",
                      borderBottom:
                        index !== array.length - 1
                          ? "1px solid #cccccc"
                          : "none",
                    }}
                  >
                    <td style={styles.label || { verticalAlign: "top" }}>
                      {fieldName}
                    </td>
                    <td style={{ verticalAlign: "top" }}>
                      <input
                        type={fieldData[fieldName].type}
                        id={fieldName}
                        name={fieldName}
                        value={formData[fieldName] || ""}
                        required={
                          !update &&
                          (fieldName === "fund_ID" ||
                            fieldName === "Fund_Name" ||
                            fieldName === "Reporting_Date" ||
                            fieldName === "portfolio_ID" ||
                            fieldName === "portfolio_companyName" ||
                            fieldName === "company_ID")
                        }
                        onInput={handleChange}
                        onChange={handleChange}
                        style={styles.input || { verticalAlign: "text-top" }}
                      />
                    </td>
                    <td style={styles.label2} width={"100%"}>
                      {fieldData[fieldName].defaultValue}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div style={styles.buttonContainer}>
            <button type="submit" style={styles.button}>
              Add
            </button>
            <button
              type="button"
              style={styles.button2}
              onClick={handleSubmitForm2}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    return (
      <div>
        <div style={styles.buttonContainer}>
          <button
            type="button"
            style={styles.button2}
            onClick={handleSubmitForm2}
          >
            Update
          </button>
        </div>
        <form onSubmit={handleSubmitForm} ref={form}>
          <table>
            <tbody>
              {Object.keys(fieldData)
                .filter((key) => key !== "pdf_link")
                .map((fieldName) => (
                  <tr key={fieldName} style={{ paddingTop: "30px" }}>
                    <td style={styles.label}>{fieldName}</td>
                    {fieldData[fieldName].type === "file" ? (
                      <>
                        <td>
                          <input
                            key={fieldName}
                            type="file"
                            id={fieldName}
                            name={fieldName}
                            onChange={handleFileChange} // Separate handler for file type
                          />
                        </td>
                        <>
                          {fieldData[fieldName].defaultValue && !imageError ? (
                            <img
                              src={fieldData[fieldName].defaultValue as string}
                              width="100px"
                              height="100px"
                              onError={handleImageError}
                              alt="Dynamic Content"
                            />
                          ) : (
                            <p>No image available</p>
                          )}
                        </>
                      </>
                    ) : (
                      <td>
                        <input
                          type={fieldData[fieldName].type}
                          id={fieldName}
                          name={fieldName}
                          value={formData[fieldName] || ""}
                          onChange={handleChange}
                          style={styles.input}
                          disabled={fieldName === "LP_name"}
                        />
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </form>
      </div>
    );
  }
};

export default DynamicForm;
