import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { useLocation } from "react-router-dom";
import { AppBar as MuiAppBar, Toolbar } from "@mui/material";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

type NavbarProps = {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  const router = useLocation();

  let pageTitle;

  if (router.pathname === "/pdf_extract") {
    pageTitle = "PDF EXTRACTION";
  } else if (router.pathname === "/pdf_summary") {
    pageTitle = "PDF SUMMARY";
  } else if (router.pathname === "/portfolio_summary") {
    pageTitle = "COMPANY INSIGHTS";
  } else if (router.pathname === "/schema_generator") {
    pageTitle = "SCHEMA GENERATOR";
  } else {
    pageTitle = "AltQ WEB INTERFACE"; // default title or another condition
  }
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          {" "}
          <div style={{ marginLeft: "45%" }}>{pageTitle}</div>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
