import React, { CSSProperties, ChangeEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";

import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import axiosInstance from "../../utils/axios/axios.instance";
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const form = React.createRef<HTMLFormElement>();

function UpdateScriptData() {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [inputvalue, setInputValue] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [staticValue, _] = useState<FileNames[]>([
    {
      Fund_Name: "VANEDGE CAPITAL II",
      Reporting_Date: "2021-06-30",
      lp_Name: "TC2009",
      fund_called: "$22.6M",
      lp_investmentStake: "0.562",
      fund_recallableDistributions: "",
      fund_fundDistributed: "",
      fund_fundSizeFinalClose: "$136,575,000",
      fund_region: "Quebec",
      portfolio_companyName: "",
      portfolio_companyOverview: "",
      portfolio_sector: "Software (IT)",
      portfolio_Status: "",
      portfolio_StatusDate: "",
      portfolio_companyCountry: "Canada",
      fund_nav: "$75.2M",
      portfolio_companyState: "",
      portfolio_firstInvestmentDate: "",
      portfolio_exitDate: "",
      unrealizedInvtCost: "$4,125,000",
      unrealizedInvtNetAssetValue: "$75,151,000",
      portfolio_fullyDilutedOwnership: "",
      portfolio_companyStates: "",
      portfolio_companyNames: "",
      portfolio_companyCountries: "",
      fund_IRR: "",
      realizedInvtGainLoss: "$13,111,198",
      realized: "Private",
      fileName: "Vanedge_Capital copy 2.json",
    },
  ]);
  const styles: { [key: string]: CSSProperties } = {
    tableLabel: {
      fontWeight: "bold",
      padding: "10px",
      textAlign: "left",
      fontSize: "15px",
    },
    label: {
      fontWeight: "bold",
      padding: "10px", // Add padding for aesthetic spacing
      textAlign: "left", // Align the text to the right within the label cell
    },
    input: {
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "70%", // Make input take full width of its cell
    },
    select: {
      display: "flex", // Make select fill the cell
      backgroundColor: "#f2f2f2", // Background color
      color: "#333", // Text color
      padding: "10px", // Padding
      border: "1px solid #ccc", // Border
      borderRadius: "5px", // Border radius
      outline: "none", // Remove outline when selected
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
      width: "200px", // Width
      cursor: "pointer", // Cursor style
    },
    defaultValue: {
      padding: "10px", // Add padding for aesthetic spacing
      marginLeft: "20px", // Space from the input cell
    },
    buttonContainer: {
      textAlign: "center",
      marginTop: "20px", // Space above the button
      marginBottom: "20px",
    },
    button: {
      padding: "12px 24px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },
  };
  const [filenames, setFileNames] = useState<FileNames[]>([]);
  interface DataObject {
    [key: string]: string | string[];
  }
  interface FileNames {
    Fund_Name: string;
    Reporting_Date: string;
    lp_Name: string;
    fund_called: string;
    lp_investmentStake: string;
    fund_recallableDistributions: string;
    fund_fundDistributed: string;
    fund_fundSizeFinalClose: string;
    fund_region: string;
    portfolio_companyName: string;
    portfolio_companyOverview: string;
    portfolio_sector: string;
    portfolio_Status: string;
    portfolio_StatusDate: string;
    portfolio_companyCountry: string;
    fund_nav: string;
    portfolio_companyState: string;
    portfolio_firstInvestmentDate: string;
    portfolio_exitDate: string;
    unrealizedInvtCost: string;
    unrealizedInvtNetAssetValue: string;
    portfolio_fullyDilutedOwnership: string;
    portfolio_companyStates: string;
    portfolio_companyNames: string;
    portfolio_companyCountries: string;
    fund_IRR: string;
    realizedInvtGainLoss: string;
    realized: string;
    fileName: string;
  }
  interface FormDataObject {
    [fieldName: string]: string;
  }
  useEffect(() => {
    document.title = "AI Extracted Data";
    getFileNames();
  }, []);
  const updateFileData = async () => {
    let formData2: FormDataObject = {};
    formData2 = inputvalue;
    const form = new FormData();
    form.append("fileName", filenames[selectedIndex].fileName);
    form.append("formData", JSON.stringify(formData2));
    try {
      const response = await axiosInstance.post("/updateDataFile", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const responseData = await response.data.data;
      console.log(responseData);
      getFileNames();
    } catch (error) {
      console.log(error);
    }
  };
  const getFileNames = async () => {
    try {
      const response = await axiosInstance.get("/fileExtraction");
      const responseData = await response.data.data;
      setFileNames(responseData);
    } catch (error) {
      console.log(error);
    }
  };
  const copyToClipboardAndPaste = (key: any, value: any) => {
    setInputValue((prevInputValue: any) => ({
      ...prevInputValue,
      [key]: value,
    }));
  };

  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        AI Extracted Data
      </Typography>
      <Divider my={6} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <div style={{ display: "flex" }}>
                <label
                  style={{
                    marginRight: "10px",
                    marginTop: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Select File :
                </label>
                <select
                  style={{
                    backgroundColor: "#f2f2f2",
                    color: "#333",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    outline: "none",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                    width: "250px",
                    cursor: "pointer",
                  }}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    if (e.target.value !== "") {
                      setIsSelected(true);
                      setSelectedIndex(parseInt(e.target.value));
                      let data: DataObject = {};
                      for (const [key, value] of Object.entries(
                        filenames[parseInt(e.target.value)]
                      )) {
                        if (Array.isArray(value) && value.length > 0) {
                          data[key] = value[0];
                        } else {
                          data[key] = value;
                        }
                      }
                      setInputValue(data);
                      if (form.current) form.current.reset();
                    } else {
                      setIsSelected(false);
                      setInputValue({});
                      if (form.current) form.current.reset();
                    }
                  }}
                >
                  <option value="">please select file</option>
                  {filenames.map((fileName, index) => {
                    return (
                      <option key={index} value={index}>
                        {filenames[index].fileName.replace(".json", "")}
                      </option>
                    );
                  })}
                </select>
              </div>
              {isSelected && (
                <div>
                  <div style={styles.buttonContainer}>
                    <button
                      onClick={() => {
                        updateFileData();
                      }}
                      type="button"
                      style={styles.button}
                    >
                      Update
                    </button>
                  </div>
                  <form
                    ref={form}
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div>
                      <table
                        style={{
                          textAlign: "left",
                          width: "100%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th style={styles.tableLabel}>Key name</th>
                            <th style={styles.tableLabel}>
                              Update value manually
                            </th>
                            <th style={styles.tableLabel}>
                              Select AI extracted values
                            </th>
                            <th style={styles.tableLabel}>Reference</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(filenames[selectedIndex]).map(
                            (key, index) => {
                              const file = filenames[selectedIndex] as {
                                [key: string]: any;
                              };
                              const refrence = staticValue[0] as {
                                [key: string]: any;
                              };
                              if (key === "fileName") return null;
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    paddingTop: "30px",
                                    borderBottom: "1px solid #cccccc",
                                  }}
                                >
                                  <td
                                    style={{
                                      ...styles.label,
                                      paddingBottom: "10px",
                                    }}
                                  >
                                    {key.replace(/_/g, " ")}{" "}
                                  </td>
                                  <td
                                    style={{
                                      ...styles.label,
                                      paddingBottom: "10px",
                                    }}
                                  >
                                    <input
                                      value={inputvalue[key] || ""}
                                      type="text"
                                      style={styles.input}
                                      onChange={(e) => {
                                        // Update inputvalue state when input changes
                                        setInputValue(
                                          (prevInputValue: any) => ({
                                            ...prevInputValue,
                                            [key]: e.target.value,
                                          })
                                        );
                                      }}
                                    />
                                  </td>
                                  <td style={{ paddingBottom: "10px" }}>
                                    <select
                                      style={styles.select}
                                      onChange={(e) => {
                                        // Get the key of the current iteration
                                        const currentKey =
                                          Object.keys(file)[index];
                                        const newValue = Array.isArray(
                                          file[currentKey]
                                        )
                                          ? file[currentKey][e.target.value]
                                          : e.target.value;

                                        setInputValue(
                                          (prevInputValue: any) => ({
                                            ...prevInputValue,
                                            [currentKey]: newValue,
                                          })
                                        );
                                      }}
                                    >
                                      {Array.isArray(file[key]) ? (
                                        file[key].map(
                                          (val: string, idx: number) => (
                                            <option key={idx} value={idx}>
                                              {val}
                                            </option>
                                          )
                                        )
                                      ) : (
                                        <option value={file[key]}>
                                          {file[key]}
                                        </option>
                                      )}
                                    </select>
                                  </td>
                                  <td
                                    style={{
                                      ...styles.label,
                                      paddingBottom: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {refrence[key]}
                                    {refrence[key] !== "" ? (
                                      <IconButton
                                        aria-label="content copy"
                                        size="small"
                                        onClick={() =>
                                          copyToClipboardAndPaste(
                                            key,
                                            refrence[key]
                                          )
                                        }
                                      >
                                        <ContentCopyIcon fontSize="inherit" />
                                      </IconButton>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </form>
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UpdateScriptData;
