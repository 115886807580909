import { CheckSquare } from "react-feather";
import { SidebarItemsType } from "../../types/sidebar";

const pagesSection = [
  {
    href: "/pdf_extract",
    icon: CheckSquare,
    title: "Pdf to Zip",
  },
  {
    href: "/add_pdf",
    icon: CheckSquare,
    title: "Add PDF",
  },
  {
    href: "/add_data",
    icon: CheckSquare,
    title: "Add/Update Data",
  },
  {
    href: "/update_script_data",
    icon: CheckSquare,
    title: "AI Extracted Data",
  },
  {
    href: "/file_extract",
    icon: CheckSquare,
    title: "PDF Extraction",
  },
  {
    href: "/update_fundRaising",
    icon: CheckSquare,
    title: "Update FundRaising",
  },
  {
    href: "/Update_AIData",
    icon: CheckSquare,
    title: "Update AI Dashboard",
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
];

export default navItems;
