/* eslint-disable prettier/prettier */
import { useState, useRef } from "react";
import axiosInstance from "../../utils/axios/axios.instance";
import { TextField } from "@mui/material";

function AddExtraction() {

  const [data, setData] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [summary, setSummary] = useState("");
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const inputEmail = useRef<HTMLInputElement | null>(null);

  const resetData = () => {
    setData("");
    setFile(null);
    setSummary("");
  };
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };
  const runscript = async () => {
    if (file) {
      let time = new Date().toISOString();
      time = time.replace(/:/g, "-");
      try {
        const formData = new FormData();
        const name = file.name.slice(0, -4);
        formData.append("pdf", file); // 'pdf' is the name the backend will use to retrieve this file
        setSummary("Processing...");
        const response = await axiosInstance.post("/runextraction", formData, {
          headers: { "Content-Type": "multipart/form-data" },
          params: {
            timestamp: time,
            name: name,
            email: inputEmail.current?.value,
          },
        });
        setSummary(response.data.data);
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("No file selected.");
    }
  };
  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (
      event.dataTransfer.items &&
      event.dataTransfer.items[0].kind === "file"
    ) {
      const droppedFile = event.dataTransfer.items[0].getAsFile();
      setFile(droppedFile);
    }
  };
  return (
    <div style={{ alignContent: "center", alignSelf: "centers" }}>
      <div
        style={{
          marginLeft: "25%",
          width: "50%",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginTop: "50px",
            border: "2px dashed gray",
            padding: "20px",
            cursor: "pointer",
            textAlign: "center",
          }}
          onDragOver={(event) => event.preventDefault()}
          onDrop={onDrop}
          onClick={() => inputFileRef.current?.click()} // 2. Use the ref to click the input
        >
          {file ? file.name : "Drag & Drop or Click to Select a File"}
          <input
            type="file"
            style={{ display: "none" }}
            onChange={onFileChange}
            onClick={(e: any) => (e.target.value = "")}
            ref={inputFileRef} // Attach the ref to the input
          />
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "20px",
            cursor: "pointer",
            textAlign: "center",
          }}
        >
          <TextField
            fullWidth
            inputRef={inputEmail}
            type="email"
            label="Email Address"
            variant="outlined"
            placeholder="email@example.com"
          />
        </div>
        <button
          onClick={runscript}
          style={{
            marginLeft: "40%",
            marginTop: "20px",
            backgroundColor: "#673AB7",
            color: "white",
            border: "none",
            padding: "12px 24px",
            borderRadius: "4px",
            fontSize: "16px",
            fontWeight: "500",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s, box-shadow 0.2s",
            cursor: "pointer",
            outline: "none",
          }}
        >
          Process
        </button>

        <div
          style={{
            alignContent: "center",
            alignItems: "center",
            marginTop: "1rem",
            marginBottom: "10%",
          }}
        >
          <div style={{ paddingTop: "10px", marginRight: "4%" }}>{data}</div>
          {data ===
            "Unfortunately we cannot process encrypted files at this time. Please come back in a few weeks for the next release" && (
            <button
              onClick={resetData}
              style={{
                marginLeft: "40%",
                marginTop: "20px",
                backgroundColor: "#D32F2F",
                color: "white",
                border: "none",
                padding: "12px 24px",
                borderRadius: "4px",
                fontSize: "16px",
                fontWeight: "500",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s, box-shadow 0.2s",
                cursor: "pointer",
                outline: "none",
              }}
            >
              Reset
            </button>
          )}
        </div>
      </div>
      {summary && (
        <>
          <div
            style={{
              backgroundColor: "white",
              border: "1px solid #e0e0e0",
              padding: "15px",
              textAlign: "justify",
            }}
          >
            <b>Summary: </b>
            {summary}
          </div>
          <button
            onClick={resetData}
            style={{
              marginLeft: "40%",
              marginTop: "20px",
              backgroundColor: "#D32F2F",
              color: "white",
              border: "none",
              padding: "12px 24px",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: "500",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s, box-shadow 0.2s",
              cursor: "pointer",
              outline: "none",
            }}
          >
            Reset
          </button>
        </>
      )}
    </div>
  );
}

export default AddExtraction;
