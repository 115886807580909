import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import axiosInstance from "../../utils/axios/axios.instance";
import {
  AIDashboard,
  Question,
  TopUpdates,
  fieldNames,
  orderArray,
} from "../../interfaces/aiDashboard.interface";
import DynamicFormAi from "./dynamicFormAi";

function Update_AIData() {
  const [fieldData, setFieldData] = useState({});
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  function createFieldData(responseData: AIDashboard): {
    [key: string]: {
      type: string | Date;
      defaultValue:
        | string
        | undefined
        | Date
        | File
        | Boolean
        | TopUpdates[]
        | Question[];
      name: string | undefined;
    };
  } {
    const excludedFields = ["_id", "publishedAt"];

    const fieldData: {
      [key: string]: {
        type: string | Date;
        defaultValue:
          | string
          | undefined
          | Date
          | File
          | Boolean
          | TopUpdates[]
          | Question[];
        name: string | undefined;
      };
    } = {};

    orderArray.forEach((key) => {
      if (excludedFields.includes(key) || !(key in responseData)) {
        return;
      }

      const value = responseData[key as keyof AIDashboard];
      let type: string;

      if (key === "fund_logo") {
        type = "file";
      } else if (typeof value === "string") {
        type = "text";
      } else if (typeof value === "boolean") {
        type = "checkbox";
      } else if (typeof value === "number") {
        type = "number";
      } else if (value instanceof Date) {
        type = "date";
      } else if (Array.isArray(value)) {
        type = "array";
      } else {
        type = typeof value;
      }

      fieldData[key] = {
        type,
        defaultValue: value,
        name: fieldNames?.[key as keyof AIDashboard],
      };
    });
    return fieldData;
  }

  const fetchData = async () => {
    try {
      setFieldData({});
      // Use params to send query parameters with a GET request
      const response = await axiosInstance.get("/ai_dashboard");
      if (response.data === "error") {
        setMessage("Error while fetching Data.");
        // Handle error
      } else {
        if (response.data.data.length === 0) {
          setMessage("There is no data with the given Date");
        } else {
          const responseData = response.data.data[0];
          setName(responseData.Fund_Name);
          console.log(responseData, "responseData");
          setFieldData(createFieldData(responseData)); // setName(responseData.fr_managerName);
          setMessage("");
        }
      }
    } catch (err) {
      setMessage("Error while fetching Data.");
      console.error(err);
    }
  };
  const handleChangeData = async (formData: {
    [fieldName: string]:
      | string
      | undefined
      | Date
      | File
      | Boolean
      | TopUpdates[]
      | Question[];
  }) => {
    try {
      const form = new FormData();
      formData.Fund_Name_original = name;
      form.append("formData", JSON.stringify(formData));
      console.log(formData, "form");
      form.append("Fund_Name2", name);
      const response = await axiosInstance.patch("/update_aiDashboard", form, {
        headers: {
          "Content-Type": "multipart/form-data", // or you can omit this line
        },
      });
      if (response.data === "error") {
        setMessage("Error while updating Data.");
      } else {
        setFieldData({});
        setMessage("Data updated successfully");
        setTimeout(() => {
          setMessage("");
          fetchData();
        }, 4000);
      }

      // Assuming you have the Base64 encoded ZIP string in a variable called `zipString`
    } catch (err) {
      setMessage("Error while updating Data.");
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Add Reporting Data" />
      <div>
        <h1>AI DASHBOARD</h1>
        {Object.keys(fieldData).length !== 0 && (
          <>
            <DynamicFormAi
              fieldData={fieldData}
              changedata={handleChangeData}
            />
          </>
        )}
        {message !== "" && <>{message}</>}
      </div>
    </React.Fragment>
  );
}
export default Update_AIData;
