import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { format } from "date-fns";
import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import FundStatic from "./fundStatic";
import FundQtr from "./fundQtr";
import PortfolioStatic from "./portfolioStatic";
import PortfolioQtr from "./portfolioQtr";
import { DatePicker } from "@mui/x-date-pickers";
import axiosInstance from "../../utils/axios/axios.instance";
import { IFundStatic } from "../../interfaces/fundStatic.interface";
import { IPortfolioQtr } from "../../interfaces/portfolioQtr.interface";
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

function AddData() {
  useEffect(() => {
    document.title = "Add Data";
    getFundNames();
    // getPortfolioNames();
  }, []);
  const [fundNames, setFundNames] = useState<Array<{ [key: string]: string }>>(
    []
  );
  const [portfolioNames, setPortfolioNames] = useState<
    Array<{ [key: string]: string }>
  >([]);
  const [selectedLayout, setSelectedLayout] = useState<string>("1");
  const [selectedFundID, setSelectedFundID] = useState("F000001");
  const [selectedPorfolioID, setSelectedPortfolioID] = useState("C000001");
  const getFundNames = async () => {
    try {
      const response = await axiosInstance.get("/get_fund_names");
      const responseData = response.data.data;
      const fundNames = responseData.map((fund: IFundStatic) => {
        return { [fund.Fund_Name]: fund.fund_ID };
      });
      setSelectedFundID(Object.values(fundNames[0])[0] as string);
      setFundNames(fundNames);
      if (fundID.current) {
        fundID.current.value = Object.values(fundNames[0])[0] as string;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getPortfolioNames = async () => {
    try {
      const response = await axiosInstance.get(
        "/getCompanies/" + fundID.current?.value
      );

      const responseData = response.data.data;
      const portfolioNames = responseData.map((portfolio: IPortfolioQtr) => {
        return {
          [portfolio.Main_Company_Name as string]: portfolio.company_ID,
        };
      });

      if (portfolioID.current) {
        portfolioID.current.value = Object.values(
          portfolioNames[0]
        )[0] as string;
        portfolioID.current.selectedIndex = 0;
      }
      setSelectedPortfolioID(Object.values(portfolioNames[0])[0] as string);
      setPortfolioNames(portfolioNames);
    } catch (error) {
      console.log(error);
    }
  };
  const changeLayout = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedLayout(event.target.value);
    if (event.target.value === "3" || event.target.value === "4") {
      getPortfolioNames();
    }
  };
  const [date, setDate] = React.useState("");
  const selectButton = useRef<HTMLSelectElement>(null);
  const fundID = useRef<HTMLSelectElement>(null);
  const portfolioID = useRef<HTMLSelectElement>(null);
  function changeDate(date: Date | null) {
    if (date instanceof Date && !isNaN(date.getTime())) {
      const formattedDate = format(date, "dd-MM-yyyy");
      setDate(formattedDate); // Make sure setDate is correctly defined in your component
    } else {
      setDate(""); // or null, or undefined, depending on how you want to handle this case
    }
  }
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Add New Data
      </Typography>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <div style={{ display: "flex" }}>
                <div>
                  <select
                    ref={selectButton}
                    onChange={changeLayout}
                    style={{
                      backgroundColor: "#f2f2f2", // Background color
                      color: "#333", // Text color
                      padding: "10px", // Padding
                      border: "1px solid #ccc", // Border
                      borderRadius: "5px", // Border radius
                      outline: "none", // Remove outline when selected
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                      width: "200px", // Width
                      cursor: "pointer", // Cursor style
                    }}
                  >
                    <option value="1">Fund Static</option>
                    <option value="2">Fund Qtr</option>
                    <option value="3">Portfolio Static</option>
                    <option value="4">Portfolio Qtr</option>
                  </select>
                </div>
                {(selectedLayout === "2" || selectedLayout === "4") && (
                  <div style={{ paddingLeft: "20px" }}>
                    <DatePicker onChange={changeDate}></DatePicker>
                  </div>
                )}
              </div>
              {selectedLayout === "1" && (
                <div style={{ paddingTop: "20px" }}>
                  <div style={{ marginRight: "20px" }}>fund ID:</div>
                  <select
                    ref={fundID}
                    onChange={() => {
                      setSelectedFundID(fundID.current?.value ?? "");
                    }}
                    style={{
                      backgroundColor: "#f2f2f2", // Background color
                      color: "#333", // Text color
                      padding: "10px", // Padding
                      border: "1px solid #ccc", // Border
                      borderRadius: "5px", // Border radius
                      outline: "none", // Remove outline when selected
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                      width: "200px", // Width
                      cursor: "pointer", // Cursor style
                    }}
                  >
                    {fundNames.map((fund, index) => {
                      const fundName = Object.keys(fund)[0];
                      const fundID = fund[fundName];
                      return (
                        <option key={index} value={fundID}>
                          {fundName}
                        </option>
                      );
                    })}
                  </select>

                  <div>
                    <FundStatic id={selectedFundID} />
                  </div>
                </div>
              )}
              {selectedLayout === "2" && (
                <div style={{ paddingTop: "20px" }}>
                  <div style={{ marginRight: "20px" }}>fund ID:</div>
                  <select
                    ref={fundID}
                    onChange={() => {
                      setSelectedFundID(fundID.current?.value ?? "");
                    }}
                    style={{
                      backgroundColor: "#f2f2f2", // Background color
                      color: "#333", // Text color
                      padding: "10px", // Padding
                      border: "1px solid #ccc", // Border
                      borderRadius: "5px", // Border radius
                      outline: "none", // Remove outline when selected
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                      width: "200px", // Width
                      cursor: "pointer", // Cursor style
                    }}
                  >
                    {fundNames.map((fund, index) => {
                      const fundName = Object.keys(fund)[0];
                      const fundID = fund[fundName];
                      return (
                        <option key={index} value={fundID}>
                          {fundName}
                        </option>
                      );
                    })}
                  </select>

                  <div>
                    <FundQtr data={date} id={selectedFundID} />
                  </div>
                </div>
              )}
              {selectedLayout === "3" && (
                <div style={{ paddingTop: "20px" }}>
                  <div style={{ marginRight: "20px" }}>Portfolio ID:</div>
                  <select
                    ref={fundID}
                    onChange={() => {
                      setSelectedFundID(fundID.current?.value ?? "");
                      getPortfolioNames();
                    }}
                    style={{
                      backgroundColor: "#f2f2f2", // Background color
                      color: "#333", // Text color
                      padding: "10px", // Padding
                      border: "1px solid #ccc", // Border
                      borderRadius: "5px", // Border radius
                      outline: "none", // Remove outline when selected
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                      width: "200px", // Width
                      cursor: "pointer", // Cursor style
                    }}
                  >
                    {fundNames.map((fund, index) => {
                      const fundName = Object.keys(fund)[0];
                      const fundID = fund[fundName];
                      return (
                        <option key={index} value={fundID}>
                          {fundName}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    ref={portfolioID}
                    onChange={() => {
                      setSelectedPortfolioID(portfolioID.current?.value ?? "");
                    }}
                    style={{
                      backgroundColor: "#f2f2f2", // Background color
                      color: "#333", // Text color
                      padding: "10px", // Padding
                      border: "1px solid #ccc", // Border
                      borderRadius: "5px", // Border radius
                      outline: "none", // Remove outline when selected
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                      width: "200px", // Width
                      cursor: "pointer", // Cursor style
                      marginLeft: "40px",
                    }}
                  >
                    {portfolioNames.map((portfolio, index) => {
                      const portfolioName = Object.keys(portfolio)[0];
                      const company_ID = portfolio[portfolioName];
                      return (
                        <option key={index} value={company_ID}>
                          {portfolioName}
                        </option>
                      );
                    })}
                  </select>

                  <div>
                    <PortfolioStatic id={selectedPorfolioID} />
                  </div>
                </div>
              )}
              {selectedLayout === "4" && (
                <div style={{ paddingTop: "20px" }}>
                  <div style={{ marginRight: "20px" }}>Portfolio ID:</div>
                  <select
                    ref={fundID}
                    onChange={() => {
                      setSelectedFundID(fundID.current?.value ?? "");
                      getPortfolioNames();
                    }}
                    style={{
                      backgroundColor: "#f2f2f2", // Background color
                      color: "#333", // Text color
                      padding: "10px", // Padding
                      border: "1px solid #ccc", // Border
                      borderRadius: "5px", // Border radius
                      outline: "none", // Remove outline when selected
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                      width: "200px", // Width
                      cursor: "pointer", // Cursor style
                    }}
                  >
                    {fundNames.map((fund, index) => {
                      const fundName = Object.keys(fund)[0];
                      const fundID = fund[fundName];
                      return (
                        <option key={index} value={fundID}>
                          {fundName}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    ref={portfolioID}
                    onChange={() => {
                      setSelectedPortfolioID(portfolioID.current?.value ?? "");
                    }}
                    style={{
                      backgroundColor: "#f2f2f2", // Background color
                      color: "#333", // Text color
                      padding: "10px", // Padding
                      border: "1px solid #ccc", // Border
                      borderRadius: "5px", // Border radius
                      outline: "none", // Remove outline when selected
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)", // Box shadow
                      width: "200px", // Width
                      cursor: "pointer", // Cursor style
                      marginLeft: "40px",
                    }}
                  >
                    {portfolioNames.map((portfolio, index) => {
                      const portfolioName = Object.keys(portfolio)[0];
                      const company_ID = portfolio[portfolioName];
                      return (
                        <option key={index} value={company_ID}>
                          {portfolioName}
                        </option>
                      );
                    })}
                  </select>

                  <div>
                    <PortfolioQtr data={date} id={selectedPorfolioID} />
                  </div>
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AddData;
